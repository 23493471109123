import axios from "axios";
import JsCookie from "js-cookie";

let isRefreshing = false;
let tokenHelper = null;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
}

export class BaseService {
    constructor(endPoint) {
        this.url = `${endPoint}`;
        this.http = axios.create({
            timeout: 50000,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + this.buildToken()
            },
            baseURL: '/',
        });
        this.http.interceptors.request.use((config) => config, (error) => Promise.reject(error));
        this.http.interceptors.response.use((response) => response, (error) => this.axiosResponse(error));
    }

    axiosResponse(error) {
        const { config, response: { status } } = error;
        const originalRequest = config;
        if (error.response.status === 401 && error.response.data.message === 'Token has expired') {
            if (!isRefreshing) {
                isRefreshing = true;

                this.refreshToken().then(response => {
                    onRefreshed(response.data.token);
                }).finally(() => {
                    isRefreshing = false;
                });
            }
            if (tokenHelper === null) {
                return new Promise((resolve, reject) => {
                    subscribeTokenRefresh((token) => {
                        tokenHelper = token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        this.http.defaults.headers['Authorization'] = 'Bearer ' + token;
                        resolve(axios(originalRequest));
                    });
                });
            } else {
                originalRequest.headers['Authorization'] = 'Bearer ' + tokenHelper;
                return axios(originalRequest);
            }
        }
        return Promise.reject(error);
    }

    buildToken() {
        let _token = JsCookie.get('77f58f9ffd') + '.' + JsCookie.get('b93cb685d') + '.' + JsCookie.get('177d5affca2');
        return _token;
    }

    refreshToken() {
        return this.http.post('api/refresh', { token: this.buildToken() });
    }

    getMany(params = {}) {
        const token = this.buildToken();
        return this.http.get(`${this.url}`, {
            params,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => response.data).catch(err => err.message);
    }

    getOne(id) {
        const token = this.buildToken();
        return this.http.get(`${this.url}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => response.data).catch(err => err.message);
    }

    saveOne(object, options = {}) {
        const token = this.buildToken();
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let { isForm = false, onUploadProgress = (e) => {} } = options;
        if (isForm === true) {
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                },
                onUploadProgress
            };
        }
        return this.http.post(`${this.url}`, object, config).then(response => response.data).catch(err => err.message);
    }

    updateOne(id, object, options = {}) {
        const token = this.buildToken();
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let { isForm = false, onUploadProgress = (e) => {} } = options;
        if (isForm === true) {
            object.append("_method", 'PATCH');
            config = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                },
                onUploadProgress
            };
            return this.http({
                method: "post",
                url: `${this.url}/${id}`,
                data: object,
                headers: config.headers
            }).then(response => response.data).catch(err => err.message);
        } else {
            return this.http.patch(`${this.url}/${id}/`, object, config).then(response => response.data).catch(err => err.message);
        }
    }

    deleteOne(id) {
        const token = this.buildToken();
        return this.http.delete(`${this.url}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => response.data).catch(err => err.message);
    }
}
