import {BaseService} from "./base.service";

class ApiKeysService extends BaseService {

    constructor() {
        super('api/apikey')
    }

    getApiAlgolia() {
        return this.http.get(`api/get-api-algolia`).then(response => response.data).catch(err => err.message);
    }

    getDistance(){
        return this.http.get(`/get-distance`).then(response => response.data).catch(err => err.message);
    }

}

export const apiKeysService = new ApiKeysService();
