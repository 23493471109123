 import {apiKeysService} from "./services/Apikeys.service";
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import {connectHits} from 'instantsearch.js/es/connectors';
import {configure, searchBox,} from 'instantsearch.js/es/widgets';
import {v4 as uuidv4} from 'uuid';

const userToken = uuidv4();

// Crear la función de renderizado
const renderHits = (renderOptions, isFirstRender) => {
    const {hits, widgetParams} = renderOptions;

    if (hits.length === 0) {
        widgetParams.container.innerHTML = `
            <ul class="ais-Hits-list">
                <li class="ais-Hits-item">Producto no encontrado</li>
            </ul>`;
    } else {
        widgetParams.container.innerHTML = `
            <ul class="ais-Hits-list">
                ${hits
            .map(hit => `<li class="ais-Hits-item">
                             <a href="/productos/${hit.friendly_url}">
                                <div class="image_prod">
                                    ${hit.img_product ? (hit.img_product === 'noimage.jpg' ? `<img src="/images/products/${hit.img_product}" alt="${hit.name}" style="width: 50px; float: left;"/>` : `<img src="/images/products/${hit.id}/${hit.img_product}" alt="${hit.name}" style="width: 50px; float: left;"/>`) : (hit.img_spare ? `<img src="/images/diagrams/${hit.img_spare}" alt="${hit.name}" style="width: 50px;"/>` : '')}  </div>
                                <div class="info_prod">
                                    <h1>${hit.name}</h1>
                                    <p>${hit.description_short?.length > 70 ? hit.description_short.substring(0, 70) + '...' : hit.description_short}</p>
                                    <small>${hit.type === 1 ? 'Producto' : 'Refacción'}</small>
                                </div>
                             </a>
                        </li>`)
            .join('')}
            </ul>`;
    }
};

// Crear el widget personalizado
const customHits = connectHits(renderHits);

// Inicializar Algolia Search
export async function initializeAlgoliaSearch() {
    const algoliaApi = await apiKeysService.getApiAlgolia();
    //console.log('algoliaApi - ', algoliaApi[0].value, algoliaApi[1].value);
    const searchClient = algoliasearch(algoliaApi[0].value, algoliaApi[1].value);

    const searchBoxContainer = window.innerWidth <= 768 ? '#mobile-searchbox' : '#desktop-searchbox';
    const hitsContainer = window.innerWidth <= 768 ? '#mobile-hits' : '#desktop-hits';

    const search = instantsearch({
        indexName: 'products', searchClient, insights: true, userToken: userToken, future: {
            preserveSharedStateOnUnmount: true,
        }
    });
    // Instanciar los widgets
    const searchBoxWidget = searchBox({
        container: searchBoxContainer,
        showReset: true,
        showSubmit: true,
        showLoadingIndicator: true,
        placeholder: 'Busca entre todas las herramientas y refacciones...',
        cssClasses: {
            submit: 'search__button search__button--type--submit', // Agrega esta línea
            input: 'search__input', // Agrega esta línea
        },
        templates: {
            submit({cssClasses}, {html}) {
                return html`<i class="fa fa-search"></i>`;
            },
        },
    });

    // Instanciar el widget personalizado
    search.addWidgets([searchBoxWidget, customHits({container: document.querySelector(hitsContainer)}), configure({hitsPerPage: 5}),]);

    search.on('render', function () {
        const query = search.helper.state.query;
        if (query && query.length > 0) {
            document.querySelector(hitsContainer).style.display = 'block';
        } else {
            document.querySelector(hitsContainer).style.display = 'none';
        }
    });

    search.start();

    // Agregar detector de eventos para ocultar los resultados de búsqueda cuando se hace clic fuera
    document.addEventListener('click', function (event) {
        const searchBoxElement = document.querySelector(searchBoxContainer);
        const hitsElement = document.querySelector(hitsContainer);
        if (!searchBoxElement.contains(event.target) && !hitsElement.contains(event.target)) {
            hitsElement.style.display = 'none';
        }
    });
}


//---- FINALIZA CONFIGURACION DE ALGOLIA ----//
